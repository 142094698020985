import { Button, Position } from '@trmediaab/zebra';
import { forwardRef } from 'react';
import styled, { keyframes } from 'styled-components';

const top = keyframes`
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 6px;
    transform: rotate(0);
  }
  100% {
    top: 6px;
    transform: rotate(45deg);
  }
`;

const top2 = keyframes`
  0% {
    top: 6px;
    transform: rotate(45deg);
  }
  50% {
    top: 6px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
`;

const middle = keyframes`
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
`;

const middle2 = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const bottom = keyframes`
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 6px;
    transform: rotate(135deg);
  }
`;

const bottom2 = keyframes`
  0% {
    bottom: 6px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
`;

const animations = {
  top: [top, top2],
  middle: [middle, middle2],
  bottom: [bottom, bottom2],
};

interface LineProps {
  open: boolean;
  color: string;
  pos: keyof typeof animations;
}

type AnimatedLineProps = React.ComponentProps<typeof Position.relative> &
  Omit<LineProps, 'color'>;

const AnimatedLine = styled(Position.relative)<AnimatedLineProps>`
  animation: ease 0.3s ${props => animations[props.pos][props.open ? 0 : 1]}
    forwards;
`;

const Line = ({ open, color, pos }: LineProps) => (
  <AnimatedLine
    as="span"
    display="block"
    width="100%"
    height="2px"
    bg={color}
    pos={pos}
    open={open}
    sx={{
      transition: 'all 0.3s',
      borderRadius: '2px',
      '& + *': {
        marginTop: '4px',
      },
    }}
  />
);

interface BurgerButtonProps {
  color?: string;
  navOpen: boolean;
  onClick: () => void;
}

const BurgerButton = forwardRef<HTMLButtonElement, BurgerButtonProps>(
  ({ color = 'white', navOpen, onClick, ...props }, ref) => (
    <>
      <Button onClick={onClick} ref={ref} aria-label="Meny" {...props}>
        <Position.absolute
          width="18px"
          top="50%"
          css={`
            transform: translateY(-50%);
          `}
        >
          <Line open={navOpen} color={color} pos="top" />
          <Line open={navOpen} color={color} pos="middle" />
          <Line open={navOpen} color={color} pos="bottom" />
        </Position.absolute>
      </Button>
    </>
  ),
);

export default BurgerButton;
