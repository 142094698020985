import { Box } from '@trmediaab/zebra';

import ArticleItem from '~/components/ArticleItem';
import type { TeaserList } from '~/types/articleList';

interface ArticleListProps {
  articles: TeaserList | undefined;
  customColumns?: (string | null)[];
  tileColor?: string;
}

const ArticleList = (props: ArticleListProps) => (
  <>
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: props.customColumns ?? [
          '1fr',
          null,
          '1fr 1fr 1fr',
          null,
        ],
        rowGap: ['3', null, '3,5', '4,5'],
        columnGap: ['3', null, '3,5', 4],
      }}
    >
      {props.articles?.results.map((article: any) => (
        <ArticleItem
          key={article.id}
          article={article}
          tileColor={props.tileColor}
        />
      ))}
    </Box>
  </>
);

export default ArticleList;
